import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p>{`Tooltips provide additional information upon hover or focus. The information should be contextual, useful, and nonessential information. Keep tooltips short.`}</p>
    <h2 {...{
      "id": "placement"
    }}>{`Placement`}</h2>
    <p>{`Icon tooltips and interactive tooltips may be positioned `}<strong parentName="p">{`top`}</strong>{`, `}<strong parentName="p">{`bottom`}</strong>{`, `}<strong parentName="p">{`left`}</strong>{`, or `}<strong parentName="p">{`right`}</strong>{` to the trigger item. The container of the tooltip text may be aligned to `}<strong parentName="p">{`start`}</strong>{`, `}<strong parentName="p">{`center`}</strong>{` or `}<strong parentName="p">{`end`}</strong>{`.`}</p>
    <p>{`Note that left and right positioning is not available for `}<a parentName="p" {...{
        "href": "#definition-tooltip"
      }}>{`definition tooltip`}</a>{`. This ensures the tooltip does not obstruct important information to the left or right of the trigger word.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "37.77173913043478%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Tooltip positiion top.",
        "title": "Tooltip positiion top.",
        "src": "/static/9f68c2b453b1690b22bfa1447f7362b7/fb070/tooltip-usage-1-top.png",
        "srcSet": ["/static/9f68c2b453b1690b22bfa1447f7362b7/d6747/tooltip-usage-1-top.png 288w", "/static/9f68c2b453b1690b22bfa1447f7362b7/09548/tooltip-usage-1-top.png 576w", "/static/9f68c2b453b1690b22bfa1447f7362b7/fb070/tooltip-usage-1-top.png 1152w", "/static/9f68c2b453b1690b22bfa1447f7362b7/c3e47/tooltip-usage-1-top.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "34.51086956521739%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Tooltip position left and right.",
        "title": "Tooltip position left and right.",
        "src": "/static/4fce6ccf8989d468c1b2db71bd649e5c/fb070/tooltip-usage-1-left-right.png",
        "srcSet": ["/static/4fce6ccf8989d468c1b2db71bd649e5c/d6747/tooltip-usage-1-left-right.png 288w", "/static/4fce6ccf8989d468c1b2db71bd649e5c/09548/tooltip-usage-1-left-right.png 576w", "/static/4fce6ccf8989d468c1b2db71bd649e5c/fb070/tooltip-usage-1-left-right.png 1152w", "/static/4fce6ccf8989d468c1b2db71bd649e5c/c3e47/tooltip-usage-1-left-right.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "37.90760869565217%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Tooltip position bottom.",
        "title": "Tooltip position bottom.",
        "src": "/static/b6834e0306810937e8fefbe937a61926/fb070/tooltip-usage-1-bottom.png",
        "srcSet": ["/static/b6834e0306810937e8fefbe937a61926/d6747/tooltip-usage-1-bottom.png 288w", "/static/b6834e0306810937e8fefbe937a61926/09548/tooltip-usage-1-bottom.png 576w", "/static/b6834e0306810937e8fefbe937a61926/fb070/tooltip-usage-1-bottom.png 1152w", "/static/b6834e0306810937e8fefbe937a61926/c3e47/tooltip-usage-1-bottom.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "variants"
    }}>{`Variants`}</h2>
    <p>{`Tooltips provide additional, contextual information. Each variant achieves this for different design needs.`}</p>
    <h3 {...{
      "id": "icon-tooltip"
    }}>{`Icon tooltip`}</h3>
    <p>{`An icon tooltip is used to clarify the action or name of an interactive icon button.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "29.34782608695652%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example image of an icon tooltip.",
        "title": "Example image of an icon tooltip.",
        "src": "/static/228214539033de946f4cd8c4b12fcb5e/fb070/tooltip-usage-1.png",
        "srcSet": ["/static/228214539033de946f4cd8c4b12fcb5e/d6747/tooltip-usage-1.png 288w", "/static/228214539033de946f4cd8c4b12fcb5e/09548/tooltip-usage-1.png 576w", "/static/228214539033de946f4cd8c4b12fcb5e/fb070/tooltip-usage-1.png 1152w", "/static/228214539033de946f4cd8c4b12fcb5e/c3e47/tooltip-usage-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h4 {...{
      "id": "guidance"
    }}>{`Guidance:`}</h4>
    <ul>
      <li parentName="ul">{`The tooltip content should only contain one or two words.`}</li>
    </ul>
    <h4 {...{
      "id": "behavior"
    }}>{`Behavior:`}</h4>
    <ul>
      <li parentName="ul">{`Icon tooltips appear on `}<inlineCode parentName="li">{`hover`}</inlineCode>{` and `}<inlineCode parentName="li">{`focus`}</inlineCode>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "definition-tooltip"
    }}>{`Definition tooltip`}</h3>
    <p>{`The definition tooltip provides additional help or defines an item or term. It may be used on the label of a UI element, or on a word embedded in a paragraph.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "46.776611694152926%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Definition tooltip used to define a word within a paragraph.",
        "title": "Definition tooltip used to define a word within a paragraph.",
        "src": "/static/9ae8f0634147b1156075e4c208dd00aa/fb070/definition_tooltip_image.png",
        "srcSet": ["/static/9ae8f0634147b1156075e4c208dd00aa/d6747/definition_tooltip_image.png 288w", "/static/9ae8f0634147b1156075e4c208dd00aa/09548/definition_tooltip_image.png 576w", "/static/9ae8f0634147b1156075e4c208dd00aa/fb070/definition_tooltip_image.png 1152w", "/static/9ae8f0634147b1156075e4c208dd00aa/f7252/definition_tooltip_image.png 1334w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h4 {...{
      "id": "guidance-1"
    }}>{`Guidance:`}</h4>
    <ul>
      <li parentName="ul">{`Should contain brief, read-only text`}</li>
      <li parentName="ul">{`Use on proper nouns, technical terms, or acronyms with two letters or more`}</li>
      <li parentName="ul">{`Do not use a definition tooltip on words with fewer than two letters`}</li>
    </ul>
    <h4 {...{
      "id": "behavior-1"
    }}>{`Behavior:`}</h4>
    <ul>
      <li parentName="ul">{`Definition tooltips appear on `}<inlineCode parentName="li">{`hover`}</inlineCode>{` and `}<inlineCode parentName="li">{`focus`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "interactive-tooltips"
    }}>{`Interactive tooltips`}</h3>
    <p>{`Interactive tooltips may contain rich text and other interactive elements like buttons or links. In general, hiding interactive content in a tooltip is discouraged. Interactive tooltips are best used for onboarding experiences and product tours.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "55.16304347826087%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of an interactive tooltip in a product walk through.",
        "title": "Example of an interactive tooltip in a product walk through.",
        "src": "/static/22cb033fd87fa24bd50097ba678ef3ff/fb070/tooltip-usage-3-v2.png",
        "srcSet": ["/static/22cb033fd87fa24bd50097ba678ef3ff/d6747/tooltip-usage-3-v2.png 288w", "/static/22cb033fd87fa24bd50097ba678ef3ff/09548/tooltip-usage-3-v2.png 576w", "/static/22cb033fd87fa24bd50097ba678ef3ff/fb070/tooltip-usage-3-v2.png 1152w", "/static/22cb033fd87fa24bd50097ba678ef3ff/c3e47/tooltip-usage-3-v2.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h4 {...{
      "id": "guidance-2"
    }}>{`Guidance:`}</h4>
    <ul>
      <li parentName="ul">{`If a user may need to visit an external resource, like while using a form, include a link in your interactive tooltip`}</li>
      <li parentName="ul">{`Don’t use without a label. Consider the context a user needs before clicking a link`}</li>
    </ul>
    <h4 {...{
      "id": "behavior-2"
    }}>{`Behavior:`}</h4>
    <ul>
      <li parentName="ul">{`Interactive tooltips appear when the user clicks on an info icon`}</li>
      <li parentName="ul">{`They persistent until intentionally dismissed by clicking outside of the tooltip`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      